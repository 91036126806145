import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/record-detail.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { TablesService } from '../../services';
import { Go } from 'src/app/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

export const LoadRecord$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadRecordDetail),
      switchMap(({ name, id }) =>
        service.getRecord(name, id).pipe(
          map(record => actions.LoadRecordDetailSuccess({ name, id, record })),
          catchError(error => of(actions.LoadRecordDetailFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveRecord$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.SaveRecordDetail),
      switchMap(({ name, id, data }) =>
        service.saveRecord(name, id, data).pipe(
          map(record => actions.SaveRecordDetailSuccess({ name, id, record })),
          catchError(error => of(actions.SaveRecordDetailFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveRecordSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(actions.SaveRecordDetailSuccess),
      tap(({ id }) => sb.open(dict.transform('form:opslaan-geslaagd'), dict.transform('form:opslaan-geslaagd-knop'), { duration: 2000 })),
      map(({ name, id, record }) =>
        Go({
          path: ['/', 'content', name, record['id'] || record['Id']],
          extras: { onSameUrlNavigation: 'ignore', replaceUrl: true },
        })
      )
    );
  },
  { functional: true }
);

export const DeleteRecord$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.DeleteRecordDetail),
      switchMap(({ name, id }) =>
        service.deleteRecord(name, id).pipe(
          map(record => actions.DeleteRecordDetailSuccess({ name, id })),
          catchError(error => of(actions.DeleteRecordDetailFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteRecordSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(actions.DeleteRecordDetailSuccess),
      tap(() => sb.open(dict.transform('form:verwijderen-geslaagd'), dict.transform('form:verwijderen-geslaagd-knop'), { duration: 2000 }))
    );
  },
  { functional: true, dispatch: false }
);
