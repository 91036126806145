import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/records.action';
import * as detailActions from '../actions/record-detail.action';
import { SqlRecord } from '../../models';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface RecordsState {
  entities: { [id: string | number]: SqlRecord };
  loading: string;
  loaded: string;
  error?: any;
}

export const initialState: RecordsState = {
  entities: {},
  loaded: '',
  loading: '',
};

export const RecordsReducer = createReducer(
  initialState,
  on(actions.LoadRecords, (state: RecordsState, { name }) => {
    return {
      ...state,
      entities: {},
      loading: name,
      loaded: '',
    };
  }),
  on(actions.LoadRecordsSuccess, (state: RecordsState, { name, records }) => {
    const entities = ToEntities(records, 'Id', {});

    return {
      ...state,
      entities,
      loading: '',
      loaded: name,
    };
  }),
  on(actions.LoadRecordsFail, (state: RecordsState, { name, error }) => {
    return {
      ...state,
      loading: '',
      loaded: '',
      error,
    };
  }),
  on(detailActions.SaveRecordDetailSuccess, (state: RecordsState, { name, id, record }) => {
    if (state.loaded === name) {
      const entities = {
        ...state.entities,
        [`${record['id'] || record['Id']}`]: record,
      };

      return {
        ...state,
        entities,
      };
    }
    return {
      ...state,
    };
  }),
  on(detailActions.DeleteRecordDetailSuccess, (state: RecordsState, { name, id }) => {
    if (state.loaded === name) {
      const { [`${id}`]: removed, ...entities } = state.entities;

      return {
        ...state,
        entities,
      };
    }
    return {
      ...state,
    };
  })
);

export const getRecordsEntities = (state: RecordsState) => state.entities;
export const getRecordsLoading = (state: RecordsState) => state.loading;
export const getRecordsLoaded = (state: RecordsState) => state.loaded;
