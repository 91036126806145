// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { SqlRecord } from '../../models';

// Load record by Id
export const LoadRecordDetail = createAction('[Records] Load Record Detail by Id', props<{ name: string; id: number | string }>());
export const LoadRecordDetailSuccess = createAction('[Records] Load Record Detail By Id Success', props<{ name: string; id: string | number; record: SqlRecord }>());
export const LoadRecordDetailFail = createAction('[Records] Load Record Detail by Id Fail', props<{ name: string; error: any }>());

// Load record by Id
export const SaveRecordDetail = createAction('[Records] Save Record Detail', props<{ name: string; id: number | string; data: { [field: string]: any } }>());
export const SaveRecordDetailSuccess = createAction('[Records] Save Record Detail Success', props<{ name: string; id: string | number; record: SqlRecord }>());
export const SaveRecordDetailFail = createAction('[Records] Save Record Detail Fail', props<{ name: string; error: any }>());

// Load record by Id
export const DeleteRecordDetail = createAction('[Records] Delete Record Detail', props<{ name: string; id: number | string }>());
export const DeleteRecordDetailSuccess = createAction('[Records] Delete Record Detail Success', props<{ name: string; id: string | number }>());
export const DeleteRecordDetailFail = createAction('[Records] Delete Record Detail Fail', props<{ name: string; error: any }>());

// Reset record detail
export const ResetRecordDetail = createAction('[Records] Reset Record Detail');
